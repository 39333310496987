<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
export default {
  page: {
    title: "Demande de retraits",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect },
  data() {
    return {
      title: "Demande de retraits",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Virements",
          active: true,
        },
      ],
      enAttentes: 0,
      effectues: 0,
      enCours: 0,
      order: [],
      ordersData: [],
      processingData: [],
      orderPaid: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterVirement: null,
      userChargeLoader: false,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "tel", sortable: true, label: "Téléphone" },
        { key: "place", sortable: true, label: "Places" },
        { key: "depart", sortable: true, label: "Départ" },
        { key: "montant", sortable: true, label: "Montant" },
        { key: "action", label: "Actions" },
      ],
      courseFields: [
        { key: "dateVirement", sortable: true, label: "Date de virement" },
        { key: "receiver", sortable: true, label: "Destinataire" },
        { key: "receiverPhone", sortable: true, label: "Téléphone" },
        { key: "montant", sortable: true, label: "Montant" },
        // { key: "action", label: "Actions" },
      ],

      demandeRetraitFields: [
        { key: "user", sortable: true, label: "Utilisateur" },
        { key: "userType", sortable: true, label: "Type" },
        { key: "date", sortable: true, label: "Date de demande" },
        { key: "userPhone", sortable: true, label: "Téléphone" },
        { key: "montant", sortable: true, label: "Montant" },
        // { key: "", sortable: true, label: "Départ" },
        // { key: "montant", sortable: true, label: "Montant" },
        { key: "action", label: "Actions" },
      ],
      ticketBusFields: [

        { key: "user", sortable: true, label: "Compagnie" },
        //{ key: "compagnie", sortable: true, label: "Compagnie" },
        { key: "date", sortable: true, label: "Date création" },
        { key: "userPhone", sortable: true, label: "Téléphone" },
        { key: "montant", sortable: true, label: "Montant" },
        // { key: "", sortable: true, label: "Départ" },
        // { key: "montant", sortable: true, label: "Montant" },
        { key: "action", label: "Actions" },
      ],
      // fieldPaid: [
      //   { key: "trajet", sortable: true, label: "Trajet" },
      //   { key: "conducteur", sortable: true, label: "Conducteur" },
      //   { key: "montant", sortable: true, label: "Montant viré" },
      //   { key: "date", sortable: true, label: "Date virement" },
      //   { key: "auto", sortable: true, label: "Mode" },
      //   { key: "admin", label: "Admin" },
      // ],
      showConfirm: false,
      showConfirmT: false,
      showConfirmW: false,
      idVirement: 0,
      showManuel: false,
      showSucessVirement: false,
      showEchecVirement: false,
      showConfirmFeda: false,
      mtnNumber: [42, 46, 50, 51, 52, 53, 54, 56, 57, 59, 61, 62, 66, 67, 69, 90, 91, 96, 97],
      fedaContrySupport: [
        {
          "contry": "Bénin Moov Momo", "code": "bj", "mode": "moov"
        },

        {
          "contry": "Côte d'Ivoire Mtn Momo", "code": "ci", "mode": "mtn_ci"
        },
        {
          "contry": "Togo  Moov Momo", "code": "tg", "mode": "moov_tg"
        }
      ],
      userFedaContryChoice: {},
      selectFedaContry: false,
      showConfirmFedaContry: false,
      virement: 0,
      virementModal: false,
      mod: "TRAJET",
    };
  },
  mounted() {
    this.retrait();
  },

  methods: {

    async retrait(){

      this.orderPaid = [];
      this.ordersData = [];
      let filterd, sort;


      const historique = await apiRequest(
        "GET",
        `admin/virements?categorie=DEMANDE_RETRAIT`,)

      console.log("HISTORIQUE,", historique.data)
            filterd = historique.data.virementsSurDemande.filter(filter => filter.id != null && filter.typeVirement == "VIREMENT")
            console.log("filtered", filterd);

            sort = filterd.sort((a, b) => b.id - a.id);

            sort.forEach(el => {

              var data = {
                userId:el.receiver.id,
                idVirement: el.id,
                date: new Date(el.dateVirement).toLocaleDateString("FR-fr") + " " + new Date(el.dateVirement).toLocaleTimeString(),
                montant: el.montant,
                user: el.receiver.personne.firstname + " " + el.receiver.personne.lastname,
                userPhone: el.receiver.personne.indicatifTel + " " + el.receiver.personne.tel,
                telephone: el.receiver.personne.tel,
                indicatifTel: el.receiver.personne.indicatifTel,
                userType: el.receiver.userType,

              }
              switch (el.statut) {
                case "PAID":
                  this.orderPaid.push(data);
                  break;
                case "PENDING":
                  this.ordersData.push(data);
                  break;
                case "PROCESSING":
                  this.processingData.push(data);
                  break;
              }
            });

            this.enAttentes = this.ordersData.length;
            this.effectues = this.orderPaid.length;
            this.enCours = this.processingData.length;


      

    },
 

   

    async updateCommissiion() {

      console.log("values", this.idSociety, "montant", this.virement)

      const data = await apiRequest("PUT",
        "admin/virement-update-amount",
        {
          idVirement: this.idVirement,
          newMontant: this.virement,

        })

      if (data.status == 200) {
        //console.log("success",data)

        this.$toast.success("Commission modifiée avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      } else {
        this.$toast.error("Un problème est survenu", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

      }
      this.virementModal = false
      ////this.init();



    },
    showVirement(row) {
      this.virement = row.montant,
        this.idVirement = row.idVirement,
        this.virementModal = true

    },

    goToUserDetail(id) {
      window.open(`/user/user_info/${id}`, '_blank', 'noreferrer');
    },
    confirmeVirer(row) {
      this.idVirement = row.idVirement;
      this.showConfirm = true;
    },
    confirmeVirerT(row) {
      this.idVirement = row.idVirement;
      this.showConfirmT = true;
    },
    confirmeVirerM(row) {
      this.idVirement = row.idVirement;
      this.showManuel = true;
    },
    confirmeVirerW(row) {
      this.idVirement = row.idVirement;
      this.showConfirmW = true;
    },


    confirmerVirerFeda(row) {
      this.idVirement = row.idVirement;
      this.showConfirmFeda = true;
    },

    confirmerVirerFedaContries(row) {
      this.idVirement = row.idVirement;
      this.selectFedaContry = true;
    },
    OnsendFeda() {


      this.showConfirmFedaContry = true,
        this.selectFedaContry = false
    },

    async checkVirer(row) {
      this.userChargeLoader = true;
      //////this.init()
      const checkVirement = await apiRequest(
        "POST",
        "admin/verification-virement-fedapay",
        { idVirement: row.idVirement },
        false
      );
      this.get
      if (checkVirement && checkVirement.data.Done === true) {
        this.userChargeLoader = false
        this.$toast.success("Le virement a abouti. Félicitation !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }

      else {
        this.userChargeLoader = false
        //////this.init()
        this.$toast.warning("Le virement n'a pas abouti. Veuillez revérifiez plus tard !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
      }

    },

    async virer() {
      this.showConfirm = false;
      const virer = await apiRequest(
        "POST",
        "admin/virer",
        { idVirement: this.idVirement },
        false
      );
      if (virer && virer.data) {
        this.showSucessVirement = true;

        this.$toast.success("Virement effectué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      } else {
        this.$toast.error("Echec du virement", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.showEchecVirement = true;
        ////this.init();
      }
    },
    async virementManuel() {
      this.showManuel = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer-manuel",
        { idVirement: this.idVirement },
        false
      );

      if (virer && virer.data) {


        this.$toast.success("Virement effectué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      } else {
        this.$toast.error("Echec du virement", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      }
    },
    async virerT() {
      this.showConfirmT = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer-tmoney",
        { idVirement: this.idVirement },
        false
      );

      if (virer && virer.data) {
        this.$toast.success("Virement effectué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });

        ////this.init();
      } else {
        this.$toast.error("Echec du virement", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      }
    },
    async virerW() {
      this.showConfirmW = false;
      const virer = await apiRequest(
        "POST",
        "admin/virer-wallet",
        { idVirement: this.idVirement },
        false
      );
      if (virer && virer.data) {
        this.showSucessVirement = true;
        this.$toast.success("Virement effectué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      } else {


        this.$toast.error("Echec du virement", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        this.showEchecVirement = true;
        ////this.init();
      }
    },

    async virerFeda() {
      this.showConfirmFeda = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer-fedapay",
        {
          idVirement: this.idVirement,
          country: 'bj',
          mode: "mtn_open"
        },
        false
      );

      if (virer && virer.data) {
        this.showSucessVirement = true;
        ////this.init();
      } else {
        this.showEchecVirement = true;
        setTimeout(() => {
          this.showEchecVirement = false;
        }, 2000);
        ////this.init();
      }
    },

    async virerFedaContries() {
      this.showConfirmFeda = false;

      const virer = await apiRequest(
        "POST",
        "admin/virer-fedapay",
        {
          idVirement: this.idVirement,
          country: this.userFedaContryChoice.code,
          mode: this.userFedaContryChoice.mode
        },
        false
      );

      if (virer && virer.data.Done
      ) {
        this.showConfirmFedaContry = false


        this.$toast.success("Virement effectué avec succès !!!", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      } else {
        this.showConfirmFedaContry = false

        this.$toast.error("Virement non effectué! Veuillez réessayer plus tard.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false
        });
        ////this.init();
      }
    },
    


    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <h5></h5>
            </div>
            <div class="row">
              <div class="col-md-4">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Virements en attente</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.enAttentes }}</h1>
                  </div>
                </b-card>
              </div>

              <div class="col-md-4">
                <b-card header-class="bg-transparent border-primary" class="border border-primary">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Virements en cours</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.enCours }}</h1>
                  </div>
                </b-card>
              </div>
              <div class="col-md-4">
                <b-card header-class="bg-transparent border-success" class="border border-success">
                  <template v-slot:header>
                    <h5 class="my-0 mb-4">Virements effectués</h5>
                  </template>
                  <div class="float-right">
                    <h1 class="text-primary">{{ this.effectues }}</h1>
                  </div>
                </b-card>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              <div class="row d-flex justify-content-between">
                <div class="col-md-6">
                  <h5>Liste des virements</h5>
                </div>
                <div class="col-md-4 form-group"> 
                </div>
              </div>

            </div>
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template #title>
                  Virement en attente <span class="rounded-circle p-1" style="background-color:aliceblue !important; width:30px !important;height:30px !important;">{{ enAttentes }}</span>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input ">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>

                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="ordersData" :fields="demandeRetraitFields" responsive="sm"
                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

                    <template v-slot:cell(montant)="row">

                      <div style="text-align: center" @click="showVirement(row.item)">
                        {{ row.value }}

                        <i class="mdi mdi-pencil font-size-18"></i>
                      </div>



                    </template>

                    <template v-slot:cell(user)="row">

                      <div @click="goToUserDetail(row.item.userId)" style="cursor:pointer">
                        <span>
                          {{ row.item.user }}
                        </span>
                        <i class="fas fa-user text-success"></i>

                      </div>



                    </template>
                    <template v-slot:cell(userPhone)="row">

                      <div  >
                        <a :href="'https://wa.me/'+(row.item.userPhone.replace('+','')).replace(/\s/g,'')" class="text-success">
                          {{ row.item.userPhone }}
                        </a>
                       

                      </div>



                    </template>
                    <template v-slot:cell(action)="row">
                      <a v-if="row.item.indicatifTel != '+228'" href="javascript:void(0);"
                        class="btn btn-success mb-1 w-100" v-b-tooltip.hover title="Virement Automatique"
                        style="width: 80px;" @click="confirmeVirer(row.item)">
                        Auto
                      </a>
                      <a v-if="row.item.indicatifTel == '+228'" href="javascript:void(0);"
                        class="btn btn-danger mb-1 w-100" v-b-tooltip.hover title="Virement TMoney" style="width: 80px;"
                        @click="confirmeVirerT(row.item)">
                        Auto
                      </a>
                      <br>
                      <a href="javascript:void(0);" class="btn btn-warning mb-1 w-100" v-b-tooltip.hover
                        title="Virement Manuel" style="width: 80px;" @click="confirmeVirerM(row.item)">
                        Manuel
                      </a>
                      <br>
                      <a href="javascript:void(0);" class="btn btn-info mb-1 w-100" v-b-tooltip.hover
                        title="Virement Wallet" style="width: 80px;" @click="confirmeVirerW(row.item)">
                        Wallet
                      </a>
                      <br>
                      <a href="javascript:void(0);"
                        v-if="row.item.indicatifTel = '+229' && mtnNumber.includes(Number(row.item.telephone.slice(0, 2)))"
                        class="btn fedaBack text-white mb-1 w-100" v-b-tooltip.hover title="Virement FedaPay"
                        style="width: 80px;" @click="confirmerVirerFeda(row.item)">
                        FedaPay
                      </a>
                      <a href="javascript:void(0);" v-else class="btn feda-back-contries text-white mb-1 w-100"
                        v-b-tooltip.hover title="Virement FedaPay" style="width: 80px;"
                        @click="confirmerVirerFedaContries(row.item)">
                        FedaPay
                      </a>
                    </template>
                  </b-table>

                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="enAttentes" :per-page="perPage"
                          :rows="ordersData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab  title-link-class="p-3">
                <template #title>
                  Virements en cours <span class="rounded-circle p-1" style="background-color:aliceblue !important; width:30px !important;height:30px !important;">{{ enCours }}</span>
                </template>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input ">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter" />
                    </div>

                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="processingData" :fields="demandeRetraitFields" responsive="sm"
                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered">

                    <template v-slot:cell(conducteur)="row">

                      <div @click="goToUserDetail(row.item.userId)">
                        <span>
                          {{ row.item.conducteur }}
                        </span>
                        <i class="fas fa-eye"></i>

                      </div>


                    </template>
                    <template v-slot:cell(tel)="row" >
                      <a :href="'https://wa.me/' + row.value" class="text-success" v-b-tooltip.hover
                        title="Envoyer un message whatsapp" target="_blank">
                        {{ row.value }}
                      </a>
                    </template>

                    <template v-slot:cell(montant)="row">

                      <div  @click="showVirement(row.item)">
                        {{ row.value }}

                        <i class="mdi mdi-pencil font-size-18"></i>
                      </div>



                    </template>
                    <template v-slot:cell(action)="row">
                      <a v-if="row.item.indicatifTel != '+228'" href="javascript:void(0);"
                        class="btn feda-back-contries mb-1 w-100" v-b-tooltip.hover title="Virement Automatique"
                        style="width: 80px;" @click="checkVirer(row.item)">
                        Vérifier
                      </a>

                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="enCours" :per-page="perPage"
                          :rows="processingData"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab  title-link-class="p-3">
                <template #title>
                  Virements effectués <span class="rounded-circle p-1" style="background-color:aliceblue !important; width:30px !important;height:30px !important;">{{ effectues }}</span>
                </template>
                <div class="card-title">

                </div>
                <div class="row d-flex justify-content-between align-items-center mt-4">
                  <div class="p-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <!-- Search -->
                  <div class="inputCol p-3">
                    <div class="input ">
                      <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filterVirement" />
                    </div>

                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table class="table-centered" :items="orderPaid" :fields="demandeRetraitFields" responsive="sm"
                    :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                    :filter="filterVirement" :filter-included-fields="filterOn" @filtered="onFiltered">
                    <template v-slot:cell(conducteur)="row">

                      <div @click="goToUserDetail(row.item.userId)">
                        <span>
                          {{ row.item.conducteur }}
                        </span>
                        <i class="fas fa-eye"></i>

                      </div>


                    </template>
                    <template v-slot:cell(montant)="row">

                      <div style="text-align: center" >
                        {{ row.value }}

                        <!-- <i class="mdi mdi-pencil font-size-18"></i> -->
                      </div>



                    </template>
                    <template v-slot:cell(paymentstatus)="row">
                      <div class="badge font-size-12" :class="{
      'badge-soft-danger': `${row.value}` === 'Chargeback',
      'badge-soft-success': `${row.value}` === 'Paid',
      'badge-soft-warning': `${row.value}` === 'Unpaid',
    }">
                        {{ row.value }}
                      </div>
                    </template>
                    <template  v-slot:cell(action)>
                      <a href="javascript:void(0);" class="btn btn-success mr-3" v-b-tooltip.hover title="Virement">
                        <i class="mdi mdi-check font-size-18"></i>
                        {{row}}
                      </a>
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="
                        dataTables_paginate
                        paging_simple_numbers
                        float-right
                      ">
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination v-model="currentPage" :total-rows="effectues" :per-page="perPage"
                          :rows="orderPaid"></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>


    <b-modal id="modal-center" centered size="sm" v-model="virementModal" title="Modifier le montant du virement"
      title-class="font-18">
      <form>
        <div class="row">
          <div class="col-md-6">
            <label for="">Montant</label>
          </div>
          <div class="col-md-6">
            <input type="number" v-model="virement" class="form-control" id="" />
          </div>

        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="virementModal = false">Fermer</b-button>
        <b-button variant="primary" @click="updateCommissiion">
          Modifier
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showConfirm"
      title="Voulez-vous vraiment faire un virement Automatique à cet utilisateur?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirm = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virer">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showManuel"
      title="Voulez-vous vraiment faire un virement Manuel à cet utilisateur?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showManuel = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virementManuel">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showConfirmT"
      title="Voulez-vous vraiment faire un virement Automatique TMoney à cet utilisateur?" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirmT = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virerT">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showConfirmW"
      title="Voulez-vous vraiment faire un virement Wallet à cet utilisateur?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirmW = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virerW">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showSucessVirement" title="Virement effectué avec succès !!!"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="success" @click="showSucessVirement = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showEchecVirement" title="Echec du virement" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecVirement = false">Reéssayez</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showConfirmFeda"
      title="Voulez-vous vraiment faire un remboursement Automatique FedaPay à cet utilisateur?" title-class="font-18"
      hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirmFeda = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virerFeda">Oui</b-button>
      </div>
    </b-modal>


    <b-modal id="modal-backdrop" centered v-model="showConfirmFedaContry"
      title="Voulez-vous vraiment faire un remboursement Automatique FedaPay à cet utilisateur? Cette action est irréversible."
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showConfirmFedaContry = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="virerFedaContries">Oui</b-button>
      </div>
    </b-modal>


    <!-- ENVOYER LE MESSAGE NOTIF-->
    <b-modal v-model="selectFedaContry" title="Virement Feda " centered>
      <form>


        <div class="form-group">
          <label for="feda-contry">Sélectionnez le <b>pays</b> ainsi que le <b>moyen de paiement</b> </label>
          <multiselect id="feda-contry" v-model="userFedaContryChoice" :options="fedaContrySupport"
            placeholder="Pays, code, paiement" label="contry"></multiselect>


        </div>

      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="selectFedaContry = false">Fermer</b-button>
        <b-button class="fedaBack" @click="OnsendFeda">
          Envoyer
          <i class="fab fa-telegram-plane ml-1"></i>
        </b-button>
      </template>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
thead {
  background: #3AAA35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}


.fedaBack {
  background: #3AAA35;
}

.btn-success{
  background-color: #3AAA35 !important;
}

.feda-back-contries {
  background: rgb(1, 1, 66);
  color: #fff;
}
</style>
